@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Paytone+One&display=swap);
* {
  font-family: "Roboto", "Open Sans", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4em;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.SnackbarItem-variantError {
  background-color: #ec5f49 !important;
}

